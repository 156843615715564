<template>
  <div class="order">
    <Header title="交易记录" />
    <div class="content">
      <div
        class="user-list"
        v-for="(i, index) in centent_user_list"
        :title="i.tab"
        :key="index"
      >
        <Record :store_list="i.data" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import Footer from "../../components/Footer.vue";
import Header from "../../components/Header2.vue";
import Record from "./components/Record.vue";
import { useStore } from "vuex";

export default {
  components: {
    Footer,
    Header,
    Record,
  },
  setup() {
    const store = useStore();
    let data = reactive({
      centent_user_list: [
        {
          tab: "数字版权",
          data: [
            {
                "thumbnailPic": "https://mj-1304255817.cos.ap-guangzhou.myqcloud.com/shjc/webapp/fm.png",
                "contentCode": "CS001",
                "title": "美家测试",
                "showType": "img",
                "orderNo": "ORDER_20220503034304208",
                "totalFee": null,
                "orderStatus": "支付成功",
                "localSource": " 测试",
                "atime": "2022-05-03 03:43:04"
            },
          ],
        },
      ],
    });

    return {
      ...toRefs(data),
      store,
    };
  },
};
</script>

<style lang='less' scoped>
.bottom {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center; /* 水平 */
  color: rgba(131, 131, 131, 1);
  font-size: 12px;
}

.order {
  margin: 0px;
  padding: 0px;
  background:   rgba(33, 30, 32, 1);
  display: flex;
  flex-flow: column;
  position: fixed;
  overflow: auto;
  width: 375px;
  height: 100%;
  .content {
    margin: 0px;
    padding: 0px;
    overflow-y: auto;
  }
}
</style>