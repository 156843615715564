<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="- 没有更多记录了 -"
    @load="onLoad"
  >
    <div class="store_list">
      <div v-for="(i, index) in list" :key="index" class="store_list_item">
        <RecordItem :item="i" />
      </div>
    </div>
  </van-list>
</template>

<script>
import RecordItem from "./RecordItem.vue";
import { onMounted, ref } from "vue";
import { Toast } from "vant";
import axios from "@/request/request";
export default {
  props: ["store_list"],
  components: {
    RecordItem,
  },
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1)
    const onLoad = () => {
      const userOpenid = JSON.parse(
        window.sessionStorage.getItem("userOpenid")
      );
      var _this = this;
      axios
        .post("app/getUserGoodsList", {
          id: userOpenid.id,
          buyType: "WXPAY",
          page: page.value,
          limit: 10,
        })
        .then(function (res) {
          if (res.status === 200 && res.data.retCode === 0) {
            let data = res.data.data.data;
            if (data) {
              
              data.forEach((item) => {
                list.value.push(item);
              });
              page.value+=1
              Toast.clear();
              loading.value = false;
              if (list.value.length >= res.data.data.count) {
                finished.value = true;
              }
            }
          } else {
            Toast.fail("获取交易记录数据失败" + res.data.retMsg);
          }
        })
        .catch(function (error) {
          Toast.fail("请检查网络" + error);
        });
    };
    onMounted(() => {
      list.value = []
      page.value = 1
    })
    return {
      list,
      onLoad,
      loading,
      finished,
      page
    };
  },
  
};
</script>

<style lang='less' scoped>
.store_list {
  margin-top: 64px;
  width: 100vw;
}
.store_list_item {
  margin-top: 10px;
  width: 100vw;
}
</style>