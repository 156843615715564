<template>
  <div class="item" @click="toStore(item)">
    <div class="top">
      <img class="top_icon" :src="item.thumbnailPic" />
      <div class="top_center">
        <div  class="top_center_left">
          <span class="top_center_text1">{{ item.title }}</span>
        <span class="top_center_text2">{{ item.contentCode }}</span>
        </div>
        <div class="top_bottom" v-if="item.transferType == null">
          <img src="@/assets/right_ok.png" />
          <p>交易成功</p>
        </div>
           <div class="top_bottom1" v-if="item.transferType != null">
          <img src="@/assets/right_ok.png" />
          <p>{{item.transactionStatus}}</p>
        </div>
      </div>
      
      <p v-if="item.showStatus" class="fee"><span style="font-size:18px;">￥</span>{{ item.totalFee/100 }}</p>
    </div>
    <div class="split_line"></div>
    <div class="bottom">
      <div class="bottom_item1" v-if="item.transferType == null">
        <p class="bottom_item1_text1">交易时间</p>
        <p class="bottom_item1_text2">{{ item.atime }}</p>
      </div>

      <div class="bottom_item2" v-if="item.transferType != null">
        <p class="bottom_item2_text1">{{ item.transferName }}</p>
        <p class="bottom_item2_text2">{{ item.nickname }}</p>
      </div>

    <div class="bottom_item2" v-if="item.transferType != null">
       <p class="bottom_item2_text1">转赠时间</p>
        <p class="bottom_item2_text2">{{ item.transferTime }}</p>
      </div>

        <div class="bottom_item2" v-if="item.transferType != null">
        <p class="bottom_item2_text1">接受时间</p>
        <p class="bottom_item2_text2">{{ item.receivedTime }}</p>
      </div>


      <div class="bottom_item2">
        <p class="bottom_item2_text1">记录单号</p>
        <p class="bottom_item2_text2">{{ item.orderNo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  props: ["item"],
  setup() {
    const router = useRouter();
    const toStore = (item) => {
      if(item.transactionStatus != "转赠成功"){
      router.push({ path: "./goodsInfo", query: { mid: item.cid } });
      }
    };
    return {
      toStore,
    };
  },
  data() {
    return {};
  },
};
</script>

<style lang='less' scoped>
.item {
  width: 355px;
  height: auto;
  padding: 0px;
  margin: auto;
  background: rgba(50, 50, 50, 1);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  .top {
    display: flex;
    // justify-content: space-between;
    align-items: flex-start;
    // flex: 1;
    height: 90px;
    align-items: center;
    .top_icon {
      width: 78px;
      height: 65px;
      margin-left: 12px;
      border-radius: 5px;
    }
    .top_center {
      display: flex;
      // flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 64px;
      width: 160px;
      margin-left: 14px;
      flex: 1;
      .top_center_left {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .top_center_text1 {
        height: 21px;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-weight: bolder;
        overflow: hidden;//溢出隐藏
      white-space: nowrap;//禁止换行
      text-overflow: ellipsis;//...
      display: block;
      width: 156px;
      }
      .top_center_text2 {
        height: 15px;
        color: rgba(166, 166, 166, 1);
        font-size: 8px;
        margin-top: 5px;
      }
    }
    .fee {
      margin-top: 60px;
      color: rgba(255, 255, 255, 1);
    }
    .top_bottom {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 21px;
      width: 78px;
      margin-left: 8px;
      margin-right: 8px;
      background: rgba(151.01605441874997, 211.0125, 142.01141249999998, 1);
      border-radius: 100px;
      img {
        width: 9px;
        height: 9px;
        margin-left: 7px;
      }
      p {
        width: 56px;
        margin-left: 2px;
        margin: 0;
        color: rgba(56, 56, 56, 1);
        font-size: 13px;
      }
    }
      .top_bottom1 {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 21px;
      width: 78px;
      margin-left: 8px;
      margin-right: 8px;
      background: linear-gradient(90deg, rgba(144, 106, 60, 1) 0%, rgba(215, 171, 114, 1) 100%);
      border-radius: 100px;
      img {
        width: 9px;
        height: 9px;
        margin-left: 7px;
      }
      p {
        width: 56px;
        margin-left: 2px;
        margin: 0;
        color: rgba(56, 56, 56, 1);
        font-size: 13px;
      }
    }
  }
  .split_line {
    width: 335px;
    height: 0px;
    margin: 0 auto;
    border: 0.5px solid rgba(71, 71, 71, 1);
  }
  .bottom {
    display: flex;
    // flex: 1;
    // height: 99px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
    .bottom_item1 {
      display: flex;
      margin-top: 3px;
      justify-content: space-between;
      width: 100%;
      .bottom_item1_text1 {
        width: 100px;
        height: 25px;
        line-height: 25px;
        margin: 0;
        color: rgba(166, 166, 166, 1);
        font-size: 14px;
      }
      .bottom_item1_text2 {
        display: inline-block;
        height: 25px;
        line-height: 25px;
        margin: 0;
        width: 200px;
        text-align: right;
        color: rgba(166, 166, 166, 1);
        font-size: 14px;
      }
    }
    .bottom_item2 {
      display: flex;
      // margin-bottom: 12px;
      justify-content: space-between;
      width: 100%;
      .bottom_item2_text1 {
        width: 100px;
        height: 25px;
        line-height: 25px;
        margin: 0;
        color: rgba(166, 166, 166, 1);
        font-size: 14px;
      }
      .bottom_item2_text2 {
        display: inline-block;
        width: 200px;
        text-align: right;
        height: 25px;
        line-height: 25px;
        margin: 0;
        color: rgba(166, 166, 166, 1);
        font-size: 14px;
        text-align: right;
      }
    }
  }
}
</style>